<template>
  <page-head-wrapper>
    <a-spin :spinning="loading">
      <div class="mp-form-wrap">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="应用名称" required>
                <span class="item-text">{{form.applicationName}}</span>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="应用简称">
                <a-input
                  v-model="form.applicationAbbreviation"
                  placeholder="请输入应用简称"
                />
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="12">
              <a-form-model-item label="AccessKey">
                <span class="item-text">{{form.applicationAccessKey}}</span>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="所属企业" prop="applicationCompany">
                <a-input
                  v-model="form.applicationCompany"
                  placeholder="请输入所属企业"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="联系电话" prop="applicationCellphone">
                <a-input
                  v-model="form.applicationCellphone"
                  placeholder="请输入联系电话"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="联系邮箱" prop="applicationEmail">
                <a-input
                  v-model="form.applicationEmail"
                  placeholder="请输入联系邮箱"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="应用状态" prop="applicationState">
                <a-radio-group v-model="form.applicationState">
                  <a-radio value="y">启用</a-radio>
                  <a-radio value="n">禁用</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="bottom-btn-wrap">
        <a-button @click="toBack" style="margin-right: 8px"> 返回 </a-button>
        <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      </div>
    </a-spin>
  </page-head-wrapper>
</template>

<script>
import {applicationDetail, applicationUpdate} from '@/api/app-permissions'
import {validateMobile, validateEmail} from '@/utils/validate'
import {mapGetters} from 'vuex'
let validatePhone = (rule, value, callback) => {
  value = value.trim()
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else if (!validateMobile(value)) {
    callback(new Error('手机号格式不正确'))
  } else {
    callback();
  }
}
let validateApplicationEmail = (rule, value, callback) => {
  value = value.trim()
  if (value === '') {
    callback(new Error('请输入邮箱'))
  } else if (!validateEmail(value)) {
    callback(new Error('邮箱格式不正确'))
  } else {
    callback();
  }
}
export default {
  computed: {
    ...mapGetters(['adminId'])
  },
  data() {
    return {
			loading: false,

      labelCol: { span: 4 },
      wrapperCol: { span: 14, offset: 1 },
      other: "",

			detailData: {},
      form: {
				applicationName: '',
				applicationAbbreviation: '',
				applicationAccessKey: '',
				applicationCompany: '',
				applicationCellphone: '',
				applicationEmail: '',
				applicationState: '',
      },
      rules: {
        applicationCompany: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        applicationCellphone: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        applicationEmail: [
          { required: true, validator: validateApplicationEmail, trigger: 'blur' },
        ],
      },
    };
  },
	created() {
		this.getDetail()
	},
  methods: {
		getDetail() {
			this.loading = true
			applicationDetail({
				applicationId: this.$route.query.id
			}).then(res => {
				console.log(res)
				if (res.code === 200) {
					this.detailData = res.data
					this.form.applicationName = res.data.applicationName
					this.form.applicationAbbreviation = res.data.applicationAbbreviation
					this.form.applicationAccessKey = res.data.applicationAccessKey
					this.form.applicationCompany = res.data.applicationCompany
					this.form.applicationCellphone = res.data.applicationCellphone
					this.form.applicationEmail = res.data.applicationEmail
					this.form.applicationState = res.data.applicationState
				} else {
					this.$message.error(res.msg || '网络异常，请稍后重试')
				}
			}).catch(err => {
				console.warn(err)
			}).finally(() => {
				this.loading = false
			})
		},
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
					console.log(this.form)
          console.log("submit!");
          this.loading = true
          applicationUpdate({
            ...this.form,
            applicationId: this.detailData.applicationId,
            applicationLastUpdateAdminId: this.adminId
          }).then(res=> {
            if (res.code === 200) {
              this.$message.success('保存成功')
              setTimeout(() => {
                this.$router.go(-1)
              }, 1000)
            } else {
              this.$message.error(res.msg || '网络异常，请稍后重试')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.item-text {
	font-size: 14px;
	color: #999999;
	line-height: 22px;
}
.bottom-btn-wrap {
  padding: 40px 0;
}
</style>